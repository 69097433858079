.login {
    background-color: white;
    border: 2px solid black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 20px;
    top: 25%;
    position: absolute;    
    
    .header {
        font-size: x-large;
        text-align: center;
        width: 100%;
        font-weight: 600;
    }

    button {
        width: 100%;
    }
}