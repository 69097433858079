body {
  background-color: #282c34;
  align-items: center;
  margin: auto;
  display: flex;
  justify-content: center;
}

.App {
  margin: 20px;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  width: calc(100% - 60px);
}

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.menu {
  max-width: 200px;

}

.container-wrapper {
  display: flex;
  gap: 20px;
}

.search {
  margin-bottom: 20px;
}

