.note {

    .wmde-markdown {
        background-color: inherit;
    }

    .tags {      
        margin-top: 15px;

        span {
            border: 1px solid black;
            border-radius: 5px;
            padding: 0 5px 0 5px;
            background-color: gray;
        }
    }

    .ant-card-head {
        min-height: initial;
        padding: 0 15px 0 15px;
    }

    .ant-card-body {
        padding: 15px;
    }
}

